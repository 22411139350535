import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import * as routerHelpers from '../../router/RouterHelpers';
import {
  IUser,
  IUserForRegister,
  IRegSuccessData,
  TRole,
  ICountryCode,
} from '../../pages/auth/interfaces';
import { TAppActions } from '../rootDuck';
import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import {
  login,
  recoveryPassword,
  newPassword,
  register,
  sendSms,
  loginByPhone,
  getCountryid,
  findInSystem,
  sendCodeConfirm,
} from '../../crud/auth.crud';
import { selectCountryId } from './authSelectors';
import { LoginType } from '../../interfaces/user';
import { getResponseMessage } from '../../utils/utils';
import {
  removeAllStorage,
  removeCancelStorage,
  removeManagerStorage,
  removeMonthStorage,
  removeSortStorage,
  removeStatusStorage,
  removeStoreStorage,
} from '../../pages/home/shifts/utils';

const CLEAR_REG = 'auth/CLEAR_REG';
const REG_REQUEST = 'auth/REG_REQUEST';
const REG_SUCCESS = 'auth/REG_SUCCESS';
const REG_FAIL = 'auth/REG_FAIL';

const CLEAR_LOGIN_BY_EMAIL = 'auth/CLEAR_LOGIN_BY_EMAIL';
const LOGIN_BY_EMAIL_REQUEST = 'auth/LOGIN_BY_EMAIL_REQUEST ';
const LOGIN_BY_EMAIL_SUCCESS = 'auth/LOGIN_BY_EMAIL_SUCCESS';
const LOGIN_BY_EMAIL_FAIL = 'auth/LOGIN_BY_EMAIL_FAIL';

const CLEAR_LOGIN_BY_PHONE = 'auth/CLEAR_LOGIN_BY_PHONE';
const LOGIN_BY_PHONE_REQUEST = 'auth/LOGIN_BY_PHONE_REQUEST ';
const LOGIN_BY_PHONE_SUCCESS = 'auth/LOGIN_BY_PHONE_SUCCESS';
const LOGIN_BY_PHONE_FAIL = 'auth/LOGIN_BY_PHONE_FAIL';

const LOGOUT = 'auth/LOGOUT';

const CLEAR_RECOVERY_PASSWORD = 'auth/CLEAR_RECOVERY_PASSWORD';
const RECOVERY_PASSWORD_REQUEST = 'auth/RECOVERY_PASSWORD_REQUEST';
const RECOVERY_PASSWORD_SUCCESS = 'auth/RECOVERY_PASSWORD_SUCCESS';
const RECOVERY_PASSWORD_FAIL = 'auth/RECOVERY_PASSWORD_FAIL';

const CLEAR_NEW_PASSWORD = 'auth/CLEAR_NEW_PASSWORD';
const NEW_PASSWORD_REQUEST = 'auth/NEW_PASSWORD_REQUEST';
const NEW_PASSWORD_SUCCESS = 'auth/NEW_PASSWORD_SUCCESS';
const NEW_PASSWORD_FAIL = 'auth/NEW_PASSWORD_FAIL';

const CLEAR_SEND_SMS = 'auth/CLEAR_SEND_SMS';
const SEND_SMS_REQUEST = 'auth/SEND_SMS_REQUEST';
const SEND_SMS_SUCCESS = 'auth/SEND_SMS_SUCCESS';
const SEND_SMS_FAIL = 'auth/SEND_SMS_FAIL';
const GET_COUNTRY_ID_SUCCESS = 'auth/GET_COUNTRY_ID_SUCCESS';
const GET_COUNTRY_ID_REQUEST = 'auth/GET_COUNTRY_ID_REQUEST';

const CLEAR_FIND_IN_SYSTEM = 'auth/CLEAR_FIND_IN_SYSTEM';
const FIND_IN_SYSTEM_REQUEST = 'auth/FIND_IN_SYSTEM_REQUEST';
const FIND_IN_SYSTEM_SUCCESS = 'auth/FIND_IN_SYSTEM_SUCCESS';
const FIND_IN_SYSTEM_FAIL = 'auth/FIND_IN_SYSTEM_FAIL';

const CLEAR_LOGIN = 'auth/CLEAR_LOGIN';
const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'auth/LOGIN_FAIL';

const CLEAR_SEND_CODE = 'auth/CLEAR_SEND_CODE';
const SEND_CODE_REQUEST = 'auth/SEND_CODE_REQUEST';
const SEND_CODE_SUCCESS = 'auth/SEND_CODE_SUCCESS';
const SEND_CODE_FAIL = 'auth/SEND_CODE_FAIL';

const SET_EMPTY_ERR_ATTEMPTS = 'auth/SET_EMPTY_ERR_ATTEMPTS';

interface AuthData {
  value: string;
  type: LoginType;

  country_name?: string;
  country_code?: string;
}

export interface IInitialState {
  email: string | undefined;
  user: IUser | undefined;
  authToken: string | undefined;

  regLoading: boolean;
  regSuccess: boolean;
  regError: string | null;

  loginByEmailLoading: boolean;
  loginByEmailSuccess: boolean;
  loginByEmailErrors: string | null;

  loginByPhoneLoading: boolean;
  loginByPhoneSuccess: boolean;
  loginByPhoneErrors: string | null;

  recoveryPasswordLoading: boolean;
  recoveryPasswordSuccess: boolean;
  recoveryPasswordErrors: string | null;

  newPasswordLoading: boolean;
  newPasswordSuccess: boolean;
  newPasswordErrors: string | null;

  sendSmsLoading: boolean;
  sendSmsSuccess: boolean;
  sendSmsErrors: string | null;
  countryId: number | null;

  findInSystemLoading: boolean;
  findInSystemSuccess: boolean;
  findInSystemError: boolean;

  sendCodeConfirmLoading: boolean;
  sendCodeConfirmSuccess: boolean;
  sendCodeConfirmError: string | null;

  loginLoading: boolean;
  loginSuccess: boolean;
  loginErrors: string | null;

  authData: AuthData | null;
}

const initialState: IInitialState = {
  email: undefined,
  user: undefined,
  authToken: undefined,

  regLoading: false,
  regSuccess: false,
  regError: null,

  loginByEmailLoading: false,
  loginByEmailSuccess: false,
  loginByEmailErrors: null,

  loginByPhoneLoading: false,
  loginByPhoneSuccess: false,
  loginByPhoneErrors: null,

  recoveryPasswordLoading: false,
  recoveryPasswordSuccess: false,
  recoveryPasswordErrors: null,

  newPasswordLoading: false,
  newPasswordSuccess: false,
  newPasswordErrors: null,

  sendSmsLoading: false,
  sendSmsSuccess: false,
  sendSmsErrors: null,
  countryId: null,

  findInSystemLoading: false,
  findInSystemSuccess: false,
  findInSystemError: false,

  loginLoading: false,
  loginSuccess: false,
  loginErrors: null,

  sendCodeConfirmLoading: false,
  sendCodeConfirmSuccess: false,
  sendCodeConfirmError: null,

  authData: null,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'auth', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_REG: {
        return { ...state, regLoading: false, regSuccess: false, regError: null };
      }

      case REG_REQUEST: {
        return {
          ...state,
          regLoading: true,
          regSuccess: false,
          regError: null,
        };
      }

      case REG_SUCCESS: {
        return {
          ...state,
          emailRequested: action.payload.data.email,
          regLoading: false,
          regSuccess: true,
        };
      }

      case REG_FAIL: {
        return {
          ...state,
          regLoading: false,
          regError: action.payload,
        };
      }

      case CLEAR_LOGIN_BY_EMAIL: {
        return {
          ...state,
          loginByEmailLoading: false,
          loginByEmailErrors: null,
          loginByEmailSuccess: false,
        };
      }

      case LOGIN_BY_EMAIL_REQUEST: {
        return {
          ...state,
          loginByEmailLoading: true,
          user: undefined,
          authToken: undefined,
          loginByEmailErrors: null,
        };
      }

      case LOGIN_BY_EMAIL_SUCCESS: {
        return {
          ...state,
          loginByEmailLoading: false,
          loginByEmailSuccess: true,
          user: action.payload.data,
          authToken: action.payload.data.api_token,
          loginByEmailErrors: null,
        };
      }

      case LOGIN_BY_EMAIL_FAIL: {
        return {
          ...state,
          loginByEmailLoading: false,
          loginByEmailSuccess: false,
          user: undefined,
          authToken: undefined,
          loginByEmailErrors: action.payload,
        };
      }

      case CLEAR_LOGIN_BY_PHONE: {
        return {
          ...state,
          loginByPhoneLoading: false,
          loginByPhoneErrors: null,
          loginByPhoneSuccess: false,
        };
      }

      case LOGIN_BY_PHONE_REQUEST: {
        return {
          ...state,
          loginByPhoneLoading: true,
          user: undefined,
          authToken: undefined,
          loginByPhoneErrors: null,
        };
      }

      case LOGIN_BY_PHONE_SUCCESS: {
        return {
          ...state,
          loginByPhoneLoading: false,
          loginByPhoneSuccess: true,
          user: action.payload.data,
          authToken: action.payload.data.api_token,
          loginByPhoneErrors: null,
        };
      }

      case LOGIN_BY_PHONE_FAIL: {
        return {
          ...state,
          loginByPhoneLoading: false,
          loginByPhoneSuccess: false,
          user: undefined,
          authToken: undefined,
          loginByPhoneErrors: action.payload,
        };
      }

      case LOGOUT: {
        routerHelpers.forgotLastLocation();
        localStorage.removeItem('location');
        removeAllStorage();
        removeStoreStorage();
        removeStatusStorage();
        removeMonthStorage();
        removeManagerStorage();
        removeCancelStorage();
        removeSortStorage();
        return initialState;
      }

      case CLEAR_RECOVERY_PASSWORD: {
        return {
          ...state,
          recoveryPasswordLoading: false,
          recoveryPasswordErrors: null,
          recoveryPasswordSuccess: false,
        };
      }

      case RECOVERY_PASSWORD_REQUEST: {
        return {
          ...state,
          recoveryPasswordLoading: true,
          recoveryPasswordErrors: null,
        };
      }

      case RECOVERY_PASSWORD_SUCCESS: {
        return {
          ...state,
          recoveryPasswordLoading: false,
          recoveryPasswordSuccess: true,
          recoveryPasswordErrors: null,
          email: action.payload.data.email,
        };
      }

      case RECOVERY_PASSWORD_FAIL: {
        return {
          ...state,
          recoveryPasswordLoading: false,
          recoveryPasswordSuccess: false,
          recoveryPasswordErrors: action.payload,
        };
      }

      case CLEAR_NEW_PASSWORD: {
        return {
          ...state,
          newPasswordLoading: false,
          newPasswordErrors: null,
          newPasswordSuccess: false,
        };
      }

      case NEW_PASSWORD_REQUEST: {
        return {
          ...state,
          newPasswordLoading: true,
          newPasswordErrors: null,
        };
      }

      case NEW_PASSWORD_SUCCESS: {
        return {
          ...state,
          newPasswordLoading: false,
          newPasswordSuccess: true,
          newPasswordErrors: null,
        };
      }

      case NEW_PASSWORD_FAIL: {
        return {
          ...state,
          newPasswordLoading: false,
          newPasswordSuccess: false,
          newPasswordErrors: action.payload,
        };
      }

      case CLEAR_SEND_SMS: {
        return {
          ...state,
          sendSmsLoading: false,
          sendSmsErrors: null,
          sendSmsSuccess: false,
        };
      }

      case SEND_SMS_REQUEST: {
        return {
          ...state,
          sendSmsLoading: true,
          sendSmsErrors: null,
        };
      }

      case SEND_SMS_SUCCESS: {
        return {
          ...state,
          sendSmsLoading: false,
          sendSmsSuccess: true,
          sendSmsErrors: null,
        };
      }

      case SEND_SMS_FAIL: {
        return {
          ...state,
          sendSmsLoading: false,
          sendSmsSuccess: false,
          sendSmsErrors: action.payload,
        };
      }

      case GET_COUNTRY_ID_SUCCESS: {
        const country = action.payload.find(
          item => item.code === '61' || item.en_name.toLowerCase() === 'australia'
        );
        if (country) {
          return {
            ...state,
            countryId: country.id,
          };
        }
        return state;
      }

      case CLEAR_FIND_IN_SYSTEM: {
        return {
          ...state,
          findInSystemLoading: false,
          findInSystemSuccess: false,
          findInSystemError: false,
          loginData: null,
        };
      }

      case FIND_IN_SYSTEM_REQUEST: {
        return {
          ...state,
          findInSystemLoading: true,
          findInSystemSuccess: false,
          findInSystemError: false,
        };
      }

      case FIND_IN_SYSTEM_SUCCESS: {
        return {
          ...state,
          findInSystemLoading: false,
          findInSystemSuccess: true,
          authData: action.payload,
        };
      }

      case FIND_IN_SYSTEM_FAIL: {
        return {
          ...state,
          findInSystemLoading: false,
          findInSystemError: true,
          authData: action.payload,
        };
      }

      // login
      case CLEAR_LOGIN: {
        return { ...state, loginLoading: false, loginErrors: null, loginSuccess: false };
      }
      case LOGIN_REQUEST: {
        return {
          ...state,
          loginLoading: true,
          user: undefined,
          authToken: undefined,
          loginErrors: null,
        };
      }
      case LOGIN_SUCCESS: {
        return {
          ...state,
          loginLoading: false,
          loginSuccess: true,
          user: action.payload.data,
          authToken: action.payload.data.api_token,
          loginErrors: null,
        };
      }
      case LOGIN_FAIL: {
        return {
          ...state,
          loginLoading: false,
          loginSuccess: false,
          user: undefined,
          authToken: undefined,
          loginErrors: action.payload,
        };
      }

      // send code
      case CLEAR_SEND_CODE: {
        return {
          ...state,
          sendCodeConfirmLoading: false,
          sendCodeConfirmSuccess: false,
          sendCodeConfirmError: null,
        };
      }
      case SEND_CODE_REQUEST: {
        return {
          ...state,
          sendCodeConfirmLoading: true,
          sendCodeConfirmSuccess: false,
          sendCodeConfirmError: null,
        };
      }
      case SEND_CODE_SUCCESS: {
        return {
          ...state,
          sendCodeConfirmLoading: false,
          sendCodeConfirmSuccess: true,
        };
      }
      case SEND_CODE_FAIL: {
        return {
          ...state,
          sendCodeConfirmLoading: false,
          sendCodeConfirmError: action.payload,
        };
      }

      case SET_EMPTY_ERR_ATTEMPTS: {
        return {
          ...state,
          emptyResErrAttempts: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearReg: () => createAction(CLEAR_REG),
  regRequest: (payload: IUserForRegister) => createAction(REG_REQUEST, payload),
  regSuccess: (payload: IServerResponse<IRegSuccessData>) =>
    createAction(REG_SUCCESS, payload),
  regFail: (payload: string) => createAction(REG_FAIL, payload),

  countryRequest: () => createAction(GET_COUNTRY_ID_REQUEST),
  countrySuccess: (payload: ICountryCode[]) => createAction(GET_COUNTRY_ID_SUCCESS, payload),

  clearLoginByEmail: () => createAction(CLEAR_LOGIN_BY_EMAIL),
  loginByEmailRequest: (payload: { login: string; password: string }) =>
    createAction(LOGIN_BY_EMAIL_REQUEST, payload),
  loginByEmailSuccess: (payload: IServerResponse<IUser>) =>
    createAction(LOGIN_BY_EMAIL_SUCCESS, payload),
  loginByEmailFail: (payload: string) => createAction(LOGIN_BY_EMAIL_FAIL, payload),

  clearLoginByPhone: () => createAction(CLEAR_LOGIN_BY_PHONE),
  loginByPhoneRequest: (payload: {
    phone: string;
    code: string;
    country_code: string;
    email?: string;
    fio?: string;
  }) => createAction(LOGIN_BY_PHONE_REQUEST, payload),
  loginByPhoneSuccess: (payload: IServerResponse<IUser>) =>
    createAction(LOGIN_BY_PHONE_SUCCESS, payload),
  loginByPhoneFail: (payload: string) => createAction(LOGIN_BY_PHONE_FAIL, payload),

  logout: () => createAction(LOGOUT),

  clearRecoveryPassword: () => createAction(CLEAR_RECOVERY_PASSWORD),
  recoveryPasswordRequest: (payload: { email: string }) =>
    createAction(RECOVERY_PASSWORD_REQUEST, payload),
  recoveryPasswordSuccess: (payload: IServerResponse<IUser>) =>
    createAction(RECOVERY_PASSWORD_SUCCESS, payload),
  recoveryPasswordFail: (payload: string) => createAction(RECOVERY_PASSWORD_FAIL, payload),

  clearNewPassword: () => createAction(CLEAR_NEW_PASSWORD),
  newPasswordRequest: (payload: { password: string; password2: string; code: string }) =>
    createAction(NEW_PASSWORD_REQUEST, payload),
  newPasswordSuccess: (payload: IServerResponse<IUser>) =>
    createAction(NEW_PASSWORD_SUCCESS, payload),
  newPasswordFail: (payload: string) => createAction(NEW_PASSWORD_FAIL, payload),

  clearSendSms: () => createAction(CLEAR_SEND_SMS),
  sendSmsRequest: (payload: {
    phone: string;
    country_code: string;
    country_name: string;
    roles?: TRole[];
    email?: string;
    fio?: string;
  }) => createAction(SEND_SMS_REQUEST, payload),
  sendSmsSuccess: () => createAction(SEND_SMS_SUCCESS),
  sendSmsFail: (payload: string) => createAction(SEND_SMS_FAIL, payload),

  clearFindInSystem: () => createAction(CLEAR_FIND_IN_SYSTEM),
  findInSystemRequest: (payload: {
    value: string;
    type: LoginType;
    country_name?: string;
    country_code?: string;
  }) => createAction(FIND_IN_SYSTEM_REQUEST, payload),
  findInSystemSuccess: (payload: {
    value: string;
    type: LoginType;
    country_name?: string;
    country_code?: string;
  }) => createAction(FIND_IN_SYSTEM_SUCCESS, payload),
  findInSystemFail: (payload: { value: string; type: LoginType }) =>
    createAction(FIND_IN_SYSTEM_FAIL, payload),

  // login
  clearLogin: () => createAction(CLEAR_LOGIN),
  loginRequest: (payload: { login: string; password: string }) =>
    createAction(LOGIN_REQUEST, payload),
  loginSuccess: (payload: IServerResponse<IUser>) => createAction(LOGIN_SUCCESS, payload),
  loginFail: (payload: string) => createAction(LOGIN_FAIL, payload),

  // send code
  clearSendCode: () => createAction(CLEAR_SEND_CODE),
  sendCodeRequest: (payload: { phone: string; roles: string[] }) =>
    createAction(SEND_CODE_REQUEST, payload),
  sendCodeSuccess: () => createAction(SEND_CODE_SUCCESS),
  sendCodeFail: (payload: string) => createAction(SEND_CODE_FAIL, payload),

  // for handle empty responses from server, check there _metronic/utils/utils.js
  setEmptyResErrAttempts: (payload: number) => createAction(SET_EMPTY_ERR_ATTEMPTS, payload),
};

export type TActions = ActionsUnion<typeof actions>;

function* regSaga({ payload }: { payload: IUserForRegister }) {
  try {
    const { data }: { data: IServerResponse<IRegSuccessData> } = yield call(() =>
      register(payload)
    );
    yield put(actions.regSuccess(data));
  } catch (e) {
    yield put(actions.regFail(e?.response?.data?.message || 'Connection error.'));
  }
}

function* loginByEmailSaga({ payload }: { payload: { login: string; password: string } }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() =>
      login(payload.login, payload.password)
    );
    yield put(actions.loginByEmailSuccess(data));
  } catch (e) {
    yield put(actions.loginByEmailFail(e?.response?.data?.message || 'Network error'));
  }
}

function* loginByPhoneSaga({
  payload,
}: {
  payload: { phone: string; code: string; country_code: string; email?: string; fio?: string };
}) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() =>
      loginByPhone(
        payload.phone,
        payload.code,
        payload.country_code,
        payload.email,
        payload.fio
      )
    );
    yield put(actions.loginByPhoneSuccess(data));
  } catch (e) {
    yield put(actions.loginByPhoneFail(e?.response?.data?.message || 'Network error'));
  }
}

function* recoveryPasswordSaga({ payload }: { payload: { email: string } }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() =>
      recoveryPassword(payload.email)
    );
    yield put(actions.recoveryPasswordSuccess(data));
  } catch (e) {
    yield put(actions.recoveryPasswordFail(e?.response?.data?.message || 'Network error'));
  }
}

function* newPasswordSaga({
  payload,
}: {
  payload: { password: string; password2: string; code: string };
}) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() => newPassword(payload));
    yield put(actions.newPasswordSuccess(data));
  } catch (e) {
    yield put(actions.newPasswordFail(e?.response?.data?.message || 'Network error'));
  }
}

function* sendSmsSaga({
  payload,
}: {
  payload: {
    phone: string;
    country_code: string;
    country_name: string;
    roles?: TRole[];
    email?: string;
    fio?: string;
  };
}) {
  const countryId: number | null = yield select(selectCountryId);
  try {
    yield call(() =>
      sendSms(
        payload.phone,
        payload.country_name,
        payload.country_code,
        payload.roles,
        countryId,
        payload.email,
        payload.fio
      )
    );
    yield put(actions.sendSmsSuccess());
  } catch (e) {
    yield put(actions.sendSmsFail(e?.response?.data?.message || 'Network error'));
  }
}

function* countryId() {
  try {
    const { data }: { data: IServerResponse<ICountryCode[]> } = yield call(() =>
      getCountryid()
    );
    yield put(actions.countrySuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

function* findInSystemSaga({
  payload,
}: {
  payload: { value: string; type: LoginType; country_name?: string; country_code?: string };
}) {
  try {
    yield call(() => findInSystem(payload));
    yield put(actions.findInSystemSuccess(payload));
  } catch (e) {
    yield put(actions.findInSystemFail(payload));
  }
}

function* loginSaga({ payload }: { payload: { login: string; password: string } }) {
  try {
    const { data }: { data: IServerResponse<IUser> } = yield call(() =>
      login(payload.login, payload.password)
    );
    yield put(actions.loginSuccess(data));
  } catch (e) {
    yield put(actions.loginFail(getResponseMessage(e)));
  }
}

function* sendCodeConfigrmSaga({ payload }: { payload: { phone: string; roles: string[] } }) {
  try {
    yield call(() => sendCodeConfirm(payload.phone, payload.roles));
    yield put(actions.sendCodeSuccess());
  } catch (e) {
    yield put(actions.sendCodeFail(getResponseMessage(e)));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.regRequest>>(REG_REQUEST, regSaga);
  yield takeLatest<ReturnType<typeof actions.loginByEmailRequest>>(
    LOGIN_BY_EMAIL_REQUEST,
    loginByEmailSaga
  );
  yield takeLatest<ReturnType<typeof actions.loginByPhoneRequest>>(
    LOGIN_BY_PHONE_REQUEST,
    loginByPhoneSaga
  );
  yield takeLatest<ReturnType<typeof actions.recoveryPasswordRequest>>(
    RECOVERY_PASSWORD_REQUEST,
    recoveryPasswordSaga
  );
  yield takeLatest<ReturnType<typeof actions.newPasswordRequest>>(
    NEW_PASSWORD_REQUEST,
    newPasswordSaga
  );
  yield takeLatest<ReturnType<typeof actions.sendSmsRequest>>(SEND_SMS_REQUEST, sendSmsSaga);
  yield takeLatest<ReturnType<typeof actions.countryRequest>>(
    GET_COUNTRY_ID_REQUEST,
    countryId
  );
  yield takeLatest<ReturnType<typeof actions.findInSystemRequest>>(
    FIND_IN_SYSTEM_REQUEST,
    findInSystemSaga
  );
  yield takeLatest<ReturnType<typeof actions.loginRequest>>(LOGIN_REQUEST, loginSaga);
  yield takeLatest<ReturnType<typeof actions.sendCodeRequest>>(
    SEND_CODE_REQUEST,
    sendCodeConfigrmSaga
  );
}
